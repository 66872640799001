export type TPageTitlePart = string | false | undefined | null;

export function getPageBrand(): string {
	if (BRAND === 'paragon') {
		return 'Paragon+';
	}

	if (BRAND === 'pari') {
		return 'PariPlay';
	}

	return 'Leaderboard';
}

export function getPageTitle(...args: [TPageTitlePart[] | TPageTitlePart, ...rest: TPageTitlePart[]]): string {
	let parts: TPageTitlePart[] = [];

	if (Array.isArray(args[0])) {
		parts = args[0] as TPageTitlePart[];
	} else {
		parts = args.filter((value) => typeof value === 'string');
	}

	parts = parts.filter(Boolean) as string[];

	return [getPageBrand(), parts.length > 0 && parts.join(' / ')].filter(Boolean).join(' // ');
}
